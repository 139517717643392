import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CBtnList,
  CFixedImg,
  LBanquetContact,
} from '../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO
        title={frontmatter?.title}
        description={frontmatter?.description}
        meta={[
          {
            name: `robots`,
            content: `noindex`,
          },
        ]}
      />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: (
              <>
                FOR TRAVEL <br className="u_sp" />
                AGENCY <br className="u_sp" />
                CUSTOMERS
              </>
            ),
            sub: '旅行代理店のお客様へ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/media/kv.png',
              },
              imgSp: {
                src: '/assets/images/media/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: '旅行代理店のお客様へ',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbMedium l_sect">
        <LWrap exClass="u_mbExLarge">
          <h2 className="c_headingLv2">旅行代理店のお客様へ</h2>
          <p className="u_mb25">
            平素より横浜ロイヤルパークホテルへ多くのお客様のご送客を賜り、厚く御礼申しあげます。
            <br />
            ダウンロードページは、当ホテルのロゴや館内写真などをカテゴリー別に掲載したデータベースです。
            <br />
            当ホテルへのご送客に際し、貴社様の営業用ツールに必要な画像やロゴマークをダウンロードいただけます。
            <br />
            ご使用方法は、下記の手順をご一読いただければ幸いでございます。
          </p>
          <p className="u_mb25">
            今後とも横浜ロイヤルパークホテルを宜しくお願い申しあげます。
          </p>
          <h2 className="c_headingLv5">ダウンロード手順</h2>
          <p className="u_mb25">
            1.
            サイトの閲覧には、ユーザー名・パスワードが必要です。ユーザー名・パスワードは当ホテル営業担当者へお問合せください。
            <br />
            2. ログイン情報を入力してください。
            <br />
            3.
            ダウンロードページが閲覧可能となり、必要な素材をダウンロードいただけます。
          </p>
          <h2 className="c_headingLv5">※ご注意</h2>
          <ul className="c_circleList">
            <li>
              全ての著作権は横浜ロイヤルパークホテルに帰属します。（無断での商用利用は一切お断りいたします。）
            </li>
            <li>
              データのご使用は、パンフレットやセールスツールなどを制作する場合に限らせていただきます。
            </li>
            <li>
              ご使用の際は、ホテル営業担当者へカンプのご提出をお願いいたします。
            </li>
          </ul>
          <CBtnList
            exClass="u_mbMedium"
            data={[
              {
                label: 'ダウンロードページへ',
                link: {
                  href: 'https://www.yrph.com/private/auth/ldx4uie7e48/',
                },
              },
            ]}
          />
          <h2 className="c_headingLv5">サンプル画像</h2>
          <CFixedImg
            width={840}
            img={{
              src: '/assets/images/media/img_media.png',
              alt: '',
            }}
          />
        </LWrap>
      </section>
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
